var Tegeldepot = Tegeldepot || {};

Tegeldepot.ProductCarousel = class {
    constructor(options) {
        this.initSlider = this.initSlider.bind(this);

        this.options = jQuery.extend({}, {
            showSlides: '4',
            carouselSelector: '.carousel',
            prevArrow: '<a href="#" class="slick-prev"><i class="ic-arrow-left"></i></a>',
            nextArrow: '<a href="#" class="slick-next"><i class="ic-arrow-right"></i></a>',
            settingsXLarge: {
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true
            },
            settingsLarge: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true
            },
            settingsMedium: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false
            },
            settingsSmall: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            },
        }, options);

        this.initSlider();
    }

    initSlider() {
        const showSlides = this.options.showSlides;
        const settingsXLarge = this.options.settingsXLarge;
        const settingsLarge = this.options.settingsLarge;
        const settingsMedium = this.options.settingsMedium;
        const settingsSmall = this.options.settingsSmall;
        const prevArrow = this.options.prevArrow;
        const nextArrow = this.options.nextArrow;

        jQuery(this.options.carouselSelector).not('.slick-initialized').slick({
            slidesToShow: showSlides,
            infinite: false,
            lazyLoad: 'ondemand',
            prevArrow: prevArrow,
            nextArrow: nextArrow,
            arrows: true,
            responsive: [
                {
                    breakpoint: '9999',
                    settings: settingsXLarge
                },
                {
                    breakpoint: bp.large,
                    settings: settingsLarge
                },
                {
                    breakpoint: bp.medium,
                    settings: settingsMedium

                },
                {
                    breakpoint: bp.small,
                    settings: settingsSmall
                }
            ]
        });
    }
};